<template>
  <div>
    <a-form
      id="components-form-demo-normal-login"
      :form="form"
      class="login-form"
      @submit="handleSubmit"
    >
      <a-form-item>
        <a-input
          size="large"
          :disabled="whileSaving || mode === 'edit'"
          v-decorator="[
            'username',
            {
              rules: [
                { required: true, message: 'Please input Username!' },
              ],
            },
          ]"
          placeholder="Username*"
        >
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input
          :disabled="whileSaving"
          v-decorator="['firstName']"
          size="large"
          placeholder="First Name"
        >
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input
          :disabled="whileSaving"
          v-decorator="['lastName']"
          size="large"
          placeholder="Last Name"
        >
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input
          :disabled="whileSaving"
          v-decorator="['email', {
              rules: [
                { required: true, message: 'Please input Email!' },
              ],
            },]"
          size="large"
          placeholder="Email*"
        >
        </a-input>
        </a-form-item>
        <a-form-item v-if="mode === 'add'">
          <a-input
            :disabled="whileSaving"
            type="password"
            v-decorator="['password', {
                rules: [
                  { required: true, message: 'Please input Password!' },
                ],
              },]"
            size="large"
            placeholder="Password*"
          >
          </a-input>
      </a-form-item>
      <a-form-item v-if="mode==='edit'">
          <a-input
            :disabled="whileSaving"
            type="password"
            v-decorator="['password']"
            size="large"
            placeholder="New Password"
          >
          </a-input>
      </a-form-item>
      <a-form-item>
        <a-input
          :disabled="whileSaving"
          v-decorator="['contactNumber']"
          size="large"
          placeholder="Contact Number"
        >
        </a-input>
      </a-form-item>
      <br />
      <a-form-item>
        <a-button
          size="large"
          type="primary"
          html-type="submit"
          class="login-form-button"
          style="width: 100%; background: #5168ea; border-color: #5168ea"
        >
          <a-icon type="lock" style="color: white" />
          <span v-if="mode === 'add'">Save SuperAdmin</span>
          <span v-if="mode === 'add' && whileSaving">Saving...</span>
          <span v-if="mode === 'edit'">Update User</span>
          <span v-if="mode === 'edit' && whileSaving">Updating...</span>
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { UserApi } from "../../services";

export default {
  props: {
    mode: String,
    item: Object,
    change: Boolean
  },
  data() {
    return {
      whileSaving: false
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "normal_login" });
    this.form.resetFields();
  },
  mounted() {
    this.form.resetFields();
    if (this.mode === 'edit') {
      this.form.setFieldsValue({
        ...this.item
      });
      this.form.setFieldsValue({
        locationId: [this.item.locationId]
      });
    }
  },
  watch: {
    item() {
      this.form.resetFields();
      if (this.mode === 'edit') {
        this.form.setFieldsValue({
          ...this.item
        });
        this.form.setFieldsValue({
          locationId: [this.item.locationId]
        });
      }
    },
    mode(nv, ov) {
      this.form.resetFields();
      if (this.mode === 'edit') {
        this.form.setFieldsValue({
          ...this.item
        });
        this.form.setFieldsValue({
          locationId: [this.item.locationId]
        });
      }
    }
  },
  methods: {
    async handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          try {
            this.whileSaving = true;
            if (this.mode === 'add') {
              await UserApi.createGSA(values);
            } else {
              values.systemId = this.item.systemId;
              await UserApi.update(values);
            }
            this.form.resetFields();
            if (this.mode === 'add') {
              this.$message.success(
                "User created successfully."
              );
            } else {
              this.$message.success(
                "User created successfully."
              );
            }
            this.$emit('success', true);
            this.whileSaving = false;
          } catch (err) {
            if (err.response.data && err.response.data.message) {
              this.$message.error(err.response.data.message);
            } else {
              this.$error.message.error(
                "Something went wrong. Please contact administrator."
              );
            }
            this.whileSaving = false;
          }
          console.log("Received values of form: ", values);
        }
      });
    },
  },
};
</script>
<style scoped>

a {
  color: #5168ea !important;
}
.auth-icon {
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.25);
  vertical-align: middle;
  margin-left: -10px;
  display: inline-block;
  margin-top: -8px;
}
</style>