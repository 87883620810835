




















































































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const UserModule = namespace("UserModule");
import { User } from "../../store/modules/users/types";
import Icon from "../../components/Icon.vue";
import UserAdd from './UserAdd.vue';
import { sortStringAttributes } from '../../services';

@Component({
  components: {
    Icon,
    UserAdd
  }
})
export default class UserList extends Vue {
  @UserModule.Getter
  public users: User[];
  public searchTerm = "";
  visible = false;
  public activeMode = "add";
  public activeItem:any = {};

  public columns = [
    {
      title: "Username",
      dataIndex: "username",
      sorter: (a, b) => sortStringAttributes(a.username, b.username),
    },
    {
      title: "Full Name",
      scopedSlots: { customRender: 'fullName' },
      slots: { title: "FullName" },
      sorter: (a, b) => sortStringAttributes(a.firstName, b.firstName),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => sortStringAttributes(a.email, b.email),
    },
    {
      title: "Contact No",
      dataIndex: "contactNumber",
      sorter: (a, b) => sortStringAttributes(a.contactNumber, b.contactNumber),
    },
    {
      title: "Role",
      dataIndex: "type",
      sorter: (a, b) => sortStringAttributes(a.type, b.type),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      sorter: (a, b) => sortStringAttributes(a.createdAt, b.createdAt),
      slots: { title: "createdAt" },
      key: 'createdAt',
      scopedSlots: { customRender: 'createdAt' },
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      sorter: (a, b) => sortStringAttributes(a.updatedAt, b.updatedAt),
      slots: { title: "updatedAt" },
      key: 'updatedAt',
      scopedSlots: { customRender: 'updatedAt' },
    },
    {
      title: "Action",
      slots: { title: "Action" },
      scopedSlots: { customRender: 'Action' },
      fixed: 'right',
      width: 150
    }
  ];
  
  @UserModule.Action
  public load!: () => Promise<User[]>;

  @UserModule.Action
  public reset!: () => Promise<void>;

  @UserModule.Action
  public search!: (term:string) => Promise<User[]>;

  async mounted() {
    await this.load();
  }

  async beforeDestroy() {
    this.reset();
  }

  get modalTitle() {
    return this.activeMode === 'add' ? 'Add Super User' : 'Edit User';
  }

  navigate(to) {
    this.$router.push(to);
  }

  onSearch(term) {
    this.searchTerm = term;
    this.search(term);
    console.log("searched");
  }

  subscriberAdded() {
    this.visible = false;
    this.load();
  }

  customRow(record) {
    return {
      on: {
        click: (event) => {
          this.$router.push(`/location/view/${record.locationId}`);
        },
      },
    };
  }
}
