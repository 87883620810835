var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'username',
          {
            rules: [
              { required: true, message: 'Please input Username!' } ],
          } ]),expression:"[\n          'username',\n          {\n            rules: [\n              { required: true, message: 'Please input Username!' },\n            ],\n          },\n        ]"}],attrs:{"size":"large","disabled":_vm.whileSaving || _vm.mode === 'edit',"placeholder":"Username*"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['firstName']),expression:"['firstName']"}],attrs:{"disabled":_vm.whileSaving,"size":"large","placeholder":"First Name"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['lastName']),expression:"['lastName']"}],attrs:{"disabled":_vm.whileSaving,"size":"large","placeholder":"Last Name"}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['email', {
            rules: [
              { required: true, message: 'Please input Email!' } ],
          } ]),expression:"['email', {\n            rules: [\n              { required: true, message: 'Please input Email!' },\n            ],\n          },]"}],attrs:{"disabled":_vm.whileSaving,"size":"large","placeholder":"Email*"}})],1),(_vm.mode === 'add')?_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['password', {
              rules: [
                { required: true, message: 'Please input Password!' } ],
            } ]),expression:"['password', {\n              rules: [\n                { required: true, message: 'Please input Password!' },\n              ],\n            },]"}],attrs:{"disabled":_vm.whileSaving,"type":"password","size":"large","placeholder":"Password*"}})],1):_vm._e(),(_vm.mode==='edit')?_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['password']),expression:"['password']"}],attrs:{"disabled":_vm.whileSaving,"type":"password","size":"large","placeholder":"New Password"}})],1):_vm._e(),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['contactNumber']),expression:"['contactNumber']"}],attrs:{"disabled":_vm.whileSaving,"size":"large","placeholder":"Contact Number"}})],1),_c('br'),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",staticStyle:{"width":"100%","background":"#5168ea","border-color":"#5168ea"},attrs:{"size":"large","type":"primary","html-type":"submit"}},[_c('a-icon',{staticStyle:{"color":"white"},attrs:{"type":"lock"}}),(_vm.mode === 'add')?_c('span',[_vm._v("Save SuperAdmin")]):_vm._e(),(_vm.mode === 'add' && _vm.whileSaving)?_c('span',[_vm._v("Saving...")]):_vm._e(),(_vm.mode === 'edit')?_c('span',[_vm._v("Update User")]):_vm._e(),(_vm.mode === 'edit' && _vm.whileSaving)?_c('span',[_vm._v("Updating...")]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }